<template>
  <div class="portfolio_list_wrap">
    <div class="portfolio_list_box" v-for="i in 3" :key="i">
      <div class="portfolio_list_img" v-for="i in 5" :key="i"></div>
      <div class="portfolio_list_date">
        <span>2021.08</span>
      </div>
      <div class="portfolio_list_text">
        <span>
          디지털 아트에 푹 빠진 아티스트경력 14년의 초보 디지털아티스트입니다. 작성한 내용이 들어갑니다. 작성한 내용이 들어갑니다. 작성한 내용이 들어갑니다.
          저에게 큐레이션을 원하시는 분은 010-0000-0000 으로 연락주세요.
        </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PortfolioListUserLayout",
  mixins: [],
  components: {
  },
  inject: [],
  provide() {
    return{

    }
  },
  props: {
  },
  data() {
    return{

    }
  },
  beforeRouterEnter() {},
  created() {
  },
  mounted() {

  },
  beforeDestroy() {
  },
  destroyed() {},
  computed: {
  },
  methods:{

  },
  watch: {

  },
}
</script>

<style scoped>

</style>